.root {
}

.subLabel {
  position: relative;
  bottom: 6px;
  padding: 0;
  color: #848484;
}

.postLabel {
  position: relative;
  top: 6px;
  bottom: 6px;
  padding: 0;
  color: #848484;
}

.selectError {
  border: var(--borderErrorField);
}
