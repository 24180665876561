.root {
}

.inputError {
  border: var(--borderErrorField);
}

.subLabel {
  position: relative;
  bottom: 6px;
  padding: 0;
  color: #848484;
}

.postLabel {
  position: relative;
  top: 6px;
  bottom: 6px;
  padding: 0;
  color: #848484;
}

.input {
  font-family: var(--fontFamily);
  font-size: 14px;
  min-height: 50px;
  font-weight: var(--fontWeightRegular);

  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;

  /* 6 + 24 + 4 + borders are divisible by 6 */
  padding: 6px 12px 4px 12px;
  background-color: #fff;

  /* Borders */
  border-radius: 6px;
  border: 1px solid #d8dce6;
  outline: none;

  /* Transition */
  transition: all ease-in-out 100ms;

  &::placeholder {
    color: var(--colorGrey300);
  }

  @media (--viewportMedium) {
    /* 7 + 32 + 7 + borders = 48, which is divisible by 8. */
    padding: 7px 16px 7px 16px;
    line-height: 32px;
  }
}

.input:hover {
  border: 1px solid #b8bfd1;
  transition: all ease-in-out 100ms;
}

.input:focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  transition: all ease-in-out 100ms;
}

.input:disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}
