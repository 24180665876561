@import '../../styles/customMediaQueries.css';
.modal {
  background: rgb(255, 255, 255);
  width: 94%;
  max-width: 500px;
  border-radius: 20px;
  font-size: 15px;
  margin: 30px auto;
  & .header {
    width: 100%;
    border-bottom: 1px solid var(--colorGrey100);
    font-size: 18px;
    text-align: left;
    padding: 15px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .content {
    width: 100%;
    padding: 15px 24px 24px;
    & > img {
      max-width: 100%;
      width: 50%;
      margin-top: 14px;
      border-radius: 10px;
    }
  }
  & .actions {
    width: 100%;
    padding: 15px;
    margin: auto;
    text-align: center;
  }
  & .close {
    cursor: pointer;
    display: block;
    padding: 0;
    line-height: 16px;
    font-size: 30px;
    font-weight: var(--fontWeightRegular);
    background: #ffffff;
    border-radius: 100px;
    border: none;
    outline: none;
    transition: var(--transitionStyle);
    &:hover {
      transition: var(--transitionStyle);
      color: var(--marketplaceColor);
    }
  }
}
.image {
  display: flex;
  justify-content: center;
}

.root {
  margin-top: 10px;
  margin-bottom: 10px;
}

.twoColumns {
  column-count: 2;
}

.item {
  display: flex;
  align-items: center;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 5px 0 3px 0;
  }
}

.checkIcon {
  height: 9px;
  width: 9px;
  margin-right: 2px;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  display: flex;
  align-items: center;
  /* margin-right: 3px; */
  margin-top: 5px;
  margin-bottom: 5px;

  /* This follows line-height */
  /* height: 24px; */
}

.labelWrapper {
  display: inline-block;
  /* display: flex; */
  margin-left: 5px;
}

.selectedLabel {
  composes: marketplaceBodyFontStyles from global;
  line-height: 24px;
  margin: 0;
  font-weight: var(--fontWeightSemiBold);
}

.listTitle {
  composes: marketplaceBodyFontStyles from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  margin-top: 20px;
}
