/* Define a base style for the stars */
.review-stars span {
  font-size: 18px; /* Adjust the size as needed */
  margin-right: 5px; /* Spacing between stars */
}

/* Style the highlighted star */
.review-stars .highlighted-star {
  color: #ff7070; /* Highlighted stars are #ff7070 */
}

.review-stars .half-star {
  position: relative;
  color: #ccc; /* Default color for empty stars */
}

.review-stars .half-star::before {
  content: '★'; /* Unicode character for a filled star */
  position: absolute;
  left: 0;
  top: -1px;
  width: 50%; /* Show only half of the star */
  overflow: hidden;
  color: #ff7070; /* Color for filled stars */
}

/* Style the grey star */
.review-stars .grey-star {
  color: #ccc; /* Grey stars are a light grey color */
}
