.chip {
  display: inline-block;
  padding: 0.1em 0.6em; /* Padding */
  border-radius: 15px; /* Rounded corners */
  color: white; /* Text color */
  font-size: 0.75em; /* Font size */
  font-weight: 500;
  text-align: center;
  margin: 0.3em 0.3em; /* Updated margin with additional left and right margins */
  cursor: default;
  white-space: nowrap;
}
