@import '../../styles/customMediaQueries.css';

.specialOfferImageBanner {
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusMedium) var(--borderRadiusMedium) 0px 0px;
  border-top: solid 1px var(--colorGrey100);
  border-left: solid 1px var(--colorGrey100);
  border-right: solid 1px var(--colorGrey100);
  padding: 10px;
  margin-bottom: 6px;
  color: var(--marketplaceColor);
  font-weight: bold;
}

.specialOfferImageBannerContent {
  column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.specialOfferListingBanner {
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusMedium);
  border: solid 1px var(--colorGrey100);
  padding: 10px;
  margin: 10px;
  color: var(--marketplaceColor);
  font-weight: bold;

  @media (--viewportMedium) {
    margin: 10px 0px;
  }
}

.specialOfferListingBannerContent {
  column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
}

.specialoffListingBannerLearnMore {
  color: var(--colorBlack);
  font-size: smaller;
  font-weight: normal;
  line-height: normal;
  margin: 6px 0px;
  text-align: center;
}

.specialoffListingBannerTerms {
  color: var(--colorBlack);
  font-size: x-small;
  font-weight: normal;
  line-height: normal;
  margin: 6px 0px;
  text-align: center;
}
