.root {
  background-color: #fff;
  justify-content: center;
  text-align: center;
  padding: 2px;
  border-bottom: 1px solid var(--colorGrey100);
  display: flex;
}

.text {
  display: inline-block;
  color: var(--colorGrey600);
  font-size: 12px;
  margin: 10px;
  line-height: 20px;
}
